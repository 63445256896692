<script setup lang="ts">
import type { ShortUser, TaskComment } from '@aedifion.io/aedifion-api'
import { useDate } from 'vuetify'
import TextEditor from '@/components/TextEditor/TextEditor.vue'
import { getJSONForTextEditor } from '@/utils/helpers/getJSONForTextEditor'
import { useUserStore } from '@/stores/user'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'
import AddOrUpdateCommentForm from './AddOrUpdateCommentForm.vue'
import { JSONContent } from '@tiptap/vue-3'
import { useI18n } from 'vue-i18n'
import moment from 'moment'

const date = useDate()

defineProps<{
  comments: TaskComment[],
  loading: boolean
}>()

const emit = defineEmits<{
  'update-comment': [payload:{ comment: JSONContent, commentID: string }]
}>()

const { t } = useI18n()

function getCommentAuthorName (author: ShortUser): string {
  return `${author?.firstName} ${author?.lastName ?? ''}`
}

function isCommentEdited (comment: TaskComment): boolean {
  if (!comment.updated) {
    return false
  }

  return moment(moment(comment.created)).isBefore(moment(comment.updated))
}

// #region COMMENT EDITING
const userStore = useUserStore()
const { userDetails } = storeToRefs(userStore)

const commentsBeingEdited = ref<Map<string, TaskComment>>(new Map())

const isCommentAuthor = (comment: TaskComment): boolean => {
  return comment.author?.id === userDetails.value?.id
}
// #endregion
</script>

<template>
  <v-container
    v-if="!loading"
    class="px-1"
  >
    <v-row
      v-for="comment in comments"
      :key="comment.id"
      class="tw-flex-nowrap"
    >
      <v-col
        class="py-2 tw-min-w-[160px] tw-max-w-[160px] tw-flex-grow-0 text-neutral-darken1"
      >
        <v-icon
          size="14"
          class="mr-2"
        >
          fa:far fa-message-lines
        </v-icon>
        {{ date.format(comment.created, 'normalDateWithWeekday') }}
      </v-col>
      <v-col class="py-2 text tw-overflow-x-hidden">
        <div
          v-if="!commentsBeingEdited.has(comment.id)"
        >
          <TextEditor
            :model-value="getJSONForTextEditor(comment.text ?? '')"
            disabled
          />
          <div
            class="tw-inline-flex tw-items-center tw-gap-2 tw-mt-2"
          >
            <span class="text-neutral-darken1 d-block">{{ getCommentAuthorName(comment.author!) }}</span>
            <span
              v-if="isCommentEdited(comment)"
              class="text-neutral-darken1"
            >· {{ t('edited') }}</span>
            <v-btn
              v-if="isCommentAuthor(comment)"
              size="24"
              class="tw-p-0"
              :data-testid="`edit-comment-button-${comment.id}`"
              @click="commentsBeingEdited.set(comment.id, comment)"
            >
              <v-icon
                size="14"
                class="text-neutral-darken1"
              >
                fa:far fa-pen
              </v-icon>
            </v-btn>
          </div>
        </div>
        <AddOrUpdateCommentForm
          v-else-if="commentsBeingEdited.has(comment.id)"
          :edited-comment="commentsBeingEdited.get(comment.id)"
          @cancel-add-comment="commentsBeingEdited.delete(comment.id)"
          @update-comment="($event ) => {
            emit('update-comment', $event)
            commentsBeingEdited.delete(comment.id)
          }"
          @submit="commentsBeingEdited.delete(comment.id)"
        />
      </v-col>
    </v-row>
  </v-container>
  <div
    v-else-if="loading"
    class="mt-n4"
  >
    <div
      v-for="i in 5"
      :key="i"
      class="tw-flex"
    >
      <div class="tw-flex">
        <v-skeleton-loader
          type="image"
          class="tw-rounded-none tw-overflow-hidden tw-bg-transparent tw-ml-1 tw-my-auto tw-grow-0"
          width="14"
          height="14"
        />
        <v-skeleton-loader
          type="text"
          class="tw-my-auto"
          width="80"
          height="50"
        />
      </div>
      <v-skeleton-loader
        type="sentences"
        class="tw-ml-12"
        width="406"
      />
    </div>
  </div>
</template>

<style lang="sass" scoped>
:deep(.tiptap)
  @apply tw-p-0
</style>

<i18n lang="json" locale="de">
  {
    "edited": "Bearbeitet"
  }
</i18n>
<i18n lang="json" locale="en">
  {
    "edited": "Edited"
  }
</i18n>
