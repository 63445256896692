<script setup lang="ts">
import type { Attribute } from '@/vuex/component_attributes_editor/types'
import type { ComponentInProjectListItemData } from '@/vuex/components_in_project/types'
import ComponentAttributeBaseRow from '../ComponentAttributeBaseRow.vue'
import ComponentHeader from '../ComponentHeader.vue'
import ComponentActions from '../ComponentActions.vue'
import ScheduleEditor from './ScheduleEditor.vue'
import ScheduleTable from './ScheduleTable.vue'
import { computed, reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import cloneDeep from 'lodash.clonedeep'
import isEqual from 'lodash/isEqual'
import { toValidJSON } from '@/utils/helpers/json'
import { convertToString } from '@/utils/helpers/strings'

interface Props {
  attribute: Attribute
  componentInProject: ComponentInProjectListItemData
}

const props = defineProps<Props>()

const emits = defineEmits<{
  'update:attribute': [ value: Attribute ]
}>()

const { t } = useI18n()

const scheduleModal = ref(false)
const scheduleEditorModal = ref(false)

// #region QUICK ACTIONS
const areAllPeriodsEmpty = computed<boolean>(() => {
  return scheduleTable.value.every((schedule) => schedule.timeSlots.length === 0)
})

type QuickActionButton = {
  title: string
  icon: string
  hidden?: boolean
  color: string
  action: () => void
}
const quickActionButtons = computed<QuickActionButton[]>(() => {
  return [
    {
      title: t('periods.Mon_Fri'),
      icon: 'fa:far fa-pen',
      color: 'neutral-darken4',
      action: () => {
        selectedDays.value = new Set([0, 1, 2, 3, 4])

        selectedPeriod.timeSlots = cloneDeep(scheduleTable.value.find((schedule) => schedule.id === 0)?.timeSlots) || []

        scheduleEditorModal.value = true
      },
    },
    {
      title: t('periods.Sat_Sun'),
      icon: 'fa:far fa-pen',
      color: 'neutral-darken4',
      action: () => {
        selectedDays.value = new Set([5, 6])

        selectedPeriod.timeSlots = cloneDeep(scheduleTable.value.find((schedule) => schedule.id === 5)?.timeSlots) || []

        scheduleEditorModal.value = true
      },
    },
    {
      title: t('periods.All_Days'),
      icon: 'fa:far fa-pen',
      color: 'neutral-darken4',
      action: () => {
        selectedDays.value = new Set([0, 1, 2, 3, 4, 5, 6])

        selectedPeriod.timeSlots = cloneDeep(scheduleTable.value.find((schedule) => schedule.id === 0)?.timeSlots) || []

        scheduleEditorModal.value = true
      },
    },
    {
      title: t('clear_all'),
      hidden: areAllPeriodsEmpty.value,
      icon: 'fa:far fa-trash',
      color: 'error-darken2',
      action: () => {
        selectedDays.value.clear()

        scheduleTable.value = scheduleTable.value.map((schedule) => {
          schedule.timeSlots = []
          return schedule
        })
      },
    },
  ]
})
// #endregion

// #region TABLE DATA
export type TimeSlotTuple = [start: string, end: string]
export type ScheduleItem = {
  id: number
  timeSlots: TimeSlotTuple[]
  day: string
}

const dayMap: { [key: string]: string } = {
  Mon: 'monday',
  Tue: 'tuesday',
  Wed: 'wednesday',
  Thu: 'thursday',
  Fri: 'friday',
  Sat: 'saturday',
  Sun: 'sunday',
} as const

type TimeSlot = {
  start: string
  end: string
}

type EmptyObject = Record<string, never>
type Schedule = {
  Mon: TimeSlot | EmptyObject
  Tue: TimeSlot | EmptyObject
  Wed: TimeSlot | EmptyObject
  Thu: TimeSlot | EmptyObject
  Fri: TimeSlot | EmptyObject
  Sat: TimeSlot | EmptyObject
  Sun: TimeSlot | EmptyObject
}

const transformSchedule = (schedule: Schedule): ScheduleItem[] => {
  const days = Object.keys(dayMap)

  return days.map((day, index) => {
    const dayData = schedule[day as keyof Schedule]
    return {
      id: index,
      timeSlots: isDayDataEmpty(dayData) ? [] : [[dayData.start, dayData.end]],
      day: t(`dates.short_weekdays.${dayMap[day]}`),
    }
  })
}

function isDayDataEmpty (dayData: TimeSlot|TimeSlotTuple|EmptyObject): boolean {
  if (Array.isArray(dayData)) {
    return !dayData[0] && !dayData[1]
  }

  return !dayData?.start && !dayData?.end
}

const scheduleTable = ref<ScheduleItem[]>(transformSchedule(toValidJSON(props.attribute.value!)))

function resetScheduleTable () {
  scheduleTable.value = transformSchedule(toValidJSON(props.attribute.value!))
}

const selectedDays = ref<Set<number>>(new Set<number>())

function toggleSelectedDay (dayId: number) {
  if (selectedDays.value.has(dayId)) {
    selectedDays.value.delete(dayId)
  } else {
    selectedDays.value.add(dayId)
  }
}
// #endregion

// #region SCHEDULE EDITOR
export type SelectedPeriod = {
  daysIds: number[]
  timeSlots: TimeSlotTuple[]
}
const selectedPeriod: SelectedPeriod = reactive({
  daysIds: computed<number[]>(() => [...selectedDays.value]),
  timeSlots: [] as TimeSlotTuple[],
})

const wasSelectedTimeSlotUpdated = computed<boolean>(() => {
  for (const selectedDay of selectedPeriod.daysIds) {
    if (!isEqual(selectedPeriod.timeSlots, scheduleTable.value.find((schedule) => schedule.id === selectedDay)?.timeSlots)) {
      return true
    }
  }

  return false
})

function handleEditTimeSlot (dayId: number) {
  const foundSchedule = scheduleTable.value.find((schedule) => schedule.id === dayId)

  selectedDays.value.add(dayId)
  selectedPeriod.timeSlots = foundSchedule ? cloneDeep(foundSchedule.timeSlots) : []

  scheduleEditorModal.value = true
}

function handleCancelEditTimeSlot () {
  selectedDays.value.clear()
  selectedPeriod.timeSlots = []

  scheduleEditorModal.value = false
}

function handleSaveEditTimeSlot () {
  [...selectedDays.value].forEach((dayId) => {
    scheduleTable.value = scheduleTable.value.map((schedule) => {
      if (schedule.id === dayId) {
        schedule.timeSlots = selectedPeriod.timeSlots
      }
      return schedule
    })
  })

  handleCancelEditTimeSlot()
}

function handleUpdateSchedule () {
  const stringifiedSchedule = convertToString(finalSchedule.value)

  emits('update:attribute', {
    ...props.attribute,
    new_value: stringifiedSchedule,
  })

  scheduleModal.value = false
}

function handleCloseScheduleModal () {
  resetScheduleTable()
  scheduleModal.value = false
}

// Currently, this feature only supports one period per day, but it will be extended in the future to support multiple periods
const finalSchedule = computed<Schedule>(() => {
  return Object.keys(dayMap).map((day, index) => {
    const dayData = scheduleTable.value.find((schedule) => schedule.id === index)?.timeSlots[0] as TimeSlotTuple
    if (!isDayDataEmpty(dayData)) {
      return {
        [day as keyof Schedule]: { start: dayData![0], end: dayData![1] },
      }
    }

    return undefined
  }).reduce((acc, curr) => ({ ...acc, ...curr }), {} as Schedule)
})

const wasScheduleUpdated = computed<boolean>(() => {
  return !isEqual(props.attribute.value ? JSON.parse(props.attribute.value) : null, finalSchedule.value)
})

function handleClearTimeSlot (index: number) {
  selectedPeriod.timeSlots[index] = ['', '']
}

function handleDeleteTimeSlot (index: number) {
  selectedPeriod.timeSlots.splice(index, 1)
}

function handleAddTimeSlot () {
  selectedPeriod.timeSlots.push(['00:00', '00:00'])
}
// #endregion
</script>

<template>
  <div>
    <ComponentAttributeBaseRow
      :attribute
      readonly
      @click="scheduleModal = true"
    />
    <v-dialog
      v-if="scheduleModal"
      v-model="scheduleModal"
      width="fit-content"
      max-height="min(calc(100vh - 80px), 800px)"
      persistent
      data-testid="scheduler-modal"
    >
      <div
        class="tw-overflow-x-hidden tw-h-full tw-bg-white tw-rounded"
      >
        <ComponentHeader
          :title="componentInProject.title"
          :description="componentInProject.description"
        />
        <div class="tw-p-6 bg-neutral-lighten3 tw-flex tw-flex-col tw-font-semibold tw-h-m tw-h-max-[calc(100%-68px)]">
          <h5 class="tw-mb-6 text-h5">
            {{ t('schedule') }}
          </h5>
          <!-- QUICK ACTIONS -->
          <div class="tw-flex tw-gap-2">
            <v-btn
              v-for="button in quickActionButtons"
              v-show="!button.hidden"
              :key="button.title"
              :data-testid="`quick-action-${button.title}`"
              class="tw-bg-white tw-rounded-full tw-h-8 tw-px-[12px]"
              color="neutral-lighten1"
              variant="outlined"
              @click="button.action"
            >
              <v-icon
                :class="['tw-mr-2', `text-${button.color}`]"
                size="14"
              >
                {{ button.icon }}
              </v-icon>
              <span
                :class="`text-${button.color}`"
                v-text="button.title"
              />
            </v-btn>
          </div>
          <!-- SCHEDULES TABLE -->
          <ScheduleTable
            :schedule-table
            :are-all-periods-empty
            @edit-time-slot="handleEditTimeSlot"
          />
          <!-- SCHEDULE EDITOR -->
          <ScheduleEditor
            v-if="scheduleEditorModal"
            v-model:dialog-state="scheduleEditorModal"
            v-model:selected-period="selectedPeriod"
            :attribute
            :component-in-project="componentInProject"
            :selected-days
            :was-selected-time-slot-updated="wasSelectedTimeSlotUpdated"
            @cancel="handleCancelEditTimeSlot"
            @save="handleSaveEditTimeSlot"
            @clear-time-slot="handleClearTimeSlot"
            @delete-time-slot="handleDeleteTimeSlot"
            @add-time-slot="handleAddTimeSlot"
            @toggle-selected-day="toggleSelectedDay"
          />
          <ComponentActions
            :disable-save="!wasScheduleUpdated"
            @cancel="handleCloseScheduleModal"
            @save="handleUpdateSchedule"
          />
          <div />
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<i18n lang="json" locale="de">
{
  "schedule": "Zeitplan",
  "periods": {
    "Mon_Fri": "Mo-Fr",
    "Sat_Sun": "Sa-So",
    "All_Days": "Alle Tage"
  },
  "clear_all": "Alle löschen"
}
</i18n>
<i18n lang="json" locale="en">
{
  "schedule": "Schedule",
  "periods": {
    "Mon_Fri": "Mon-Fri",
    "Sat_Sun": "Sat-Sun",
    "All_Days": "All Days"
  },
  "clear_all": "Clear all"
}
</i18n>
